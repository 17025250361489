import Head from 'next/head';
import { Box, Button, Container, Typography } from '@mui/material';

const NotFound = () => (
  <>
    <Head>
      <title>
        404
      </title>
    </Head>
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }} 
        >
          <Typography
            align="center"
            color="textPrimary"
            variant="h5"
          >
            페이지를 찾을 수 없습니다.<br/>
            입력하신 주소가 정확한지 다시 한 번 확인해 주시기바랍니다.
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Under development"
              src="/static/images/undraw_page_not_found_su7k.svg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560
              }}
            />
          </Box>
          <div style={{display: 'flex'}}>
          <Button
            component="a"
            variant="contained"
            sx={{ ml: 3 }} 
            onClick={() => Router.back()}
          >
            뒤로가기
          </Button>
          <Button
            component="a"
            variant="contained"
            sx={{ ml: 3 }}
            onClick={() => {window.location.href="/"}}
          >
            홈으로 가기
          </Button>
          </div>
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;

// import Error from 'next/error'

// export default function NotFound() {
//   // Opinionated: do not record an exception in Sentry for 404
//   return <Error statusCode={404} />
// }
